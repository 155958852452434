import React, {useEffect} from 'react';
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import MainRoutes from "@routing/main-routes";
import Footer from "@components/footer/footer";
import icon_sky_pay from "@assets/sky_pay.svg";
import { useStore } from "@hooks/use-store";

const Index = () => {

    const { mainStore } = useStore();
    let params = useParams();

    useEffect(() => {
        const id = params['*']?.replace(/^.*\//, "") ?? "";
        mainStore.checkPayment(id).then();
        mainStore.loadDataFromStorage(id);
    }, []);

    return (
        <div className="sc-dNLxif dOqzll">
            <main className="sc-jqCOkK CmQLs">
                <div className="styles_container__3Tbyu">

                    <div className="styles_header__3XHS1">
                        <a href={"/"}>
                            <img src={icon_sky_pay} alt="logo" className="styles_logo__4vkif"/>
                        </a>
                        <div className="styles_title__BTkq_">БЕЗОПАСНАЯ ОПЛАТА</div>
                        <div/>
                    </div>

                    <div className="styles_stepsContainer__hxAUB">
                        <MainRoutes/>
                        <Footer />
                    </div>

                </div>
            </main>
        </div>
    );

};

export default observer(Index);
