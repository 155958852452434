import mitt from 'mitt';
import { makeObservable, observable, computed, action } from "mobx";

import MainStore from "@store/main-store";

export class RootStore {

    // эмиттер для шины сообщений
    emitter = mitt();

    _showProgressBar = false;

    get showProgressBar() {
        return this._showProgressBar;
    }
    setShowProgressBar(val: boolean) {
        this._showProgressBar = val;
    }

    rootStore            = this;

    mainStore            = new MainStore(this);

    constructor() {
        makeObservable(this, {
            _showProgressBar: observable,
            showProgressBar: computed,
            setShowProgressBar: action
        });
    }

}
