import { instance } from "./service";

class Api {

    config() {
        return instance.get("/config");
    }

    payment(payment_id: string) {
        return instance.get("/order/" + payment_id);
    }

    cancel(payment_id: string, auto: number) {
        return instance.get("/cancel/" + payment_id + "/" + auto);
    }

    payout(client_id: string, wallet: string, code: string, summ: any) {
        return instance.post("/payout", {
            client_id: client_id,
            wallet: wallet,
            code: code,
            summ: summ
        });
    }

}

export default new Api();
