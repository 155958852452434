import log from "loglevel";
import { nanoid } from 'nanoid';
import { io } from 'socket.io-client';
import Config from "@config/config";
import { SOCKET_MESSAGE } from "@config/constats";


/**
 * Класс для работы с сокетами.
 * Все отправляемые сообщения сохраняются в истории (history) и хранятся там до получения ответа-подтверждения от
 * сервера или до истечения срока жизни (MESSAGE_LIFETIME).
 */
class Sockets {

    // время жизни сообщения, в течение которого должно быть получено подтверждение ответным сообщением от сервера, миллисек
    MESSAGE_LIFETIME = 30 * 1000;

    socket: any = null;
    // история запросов для отлова ошибки авторизации (истечение токена)
    history: any = [];
    // подписчики на сообщения сокета
    subscribers: any = {};

    constructor() {
    }

    init() {
        const host = Config.API.replace(/\/[^/]+$/, "/");
        console.log(Config.API, host)
        const socket = io(host, {
            auth: {
                "x-auth-token": localStorage.getItem("token")
            },
            autoConnect: true,
            transports: ['websocket'],
            secure: true,
        });
        this.socket = socket;

        socket.on(SOCKET_MESSAGE, this.onMessage.bind(this));
        socket.on("connect_error", this.onError.bind(this));
    }

    /**
     * Подписка на сообщения сокета
     * @param name          Имя обработчика подписки (для уникализации)
     * @param callback
     */
    subscribe(name: string, callback: any) {
        log.debug("subscribe name:" + name, callback);
        if (!this.subscribers[name]) {
            this.subscribers[name] = callback;
        }
    }

    unsubscribe(name: string) {
        log.debug("unsubscribe name:", name);
        delete(this.subscribers[name]);
    }

    async onError(err: any) {
        log.debug("onError", err);
    }

    addToHistory(message:any) {
        log.debug("addToHistory", message);
        message.timestamp = Date.now();
        this.history[message['msg_id']] = message;
    }

    removeFromHistory(msg_id:string) {
        log.debug("removeFromHistory msg_id:", msg_id);
        if (this.history[msg_id]) {
            delete(this.history[msg_id]);
        }
    }

    sendMessage(data: any, msg_id: string|null = null) {
        const message = {
            msg_id: msg_id ?? nanoid(),
            data: data
        }
        log.debug("sendMessage message", message);
        this.addToHistory(message);
        this.socket.emit(SOCKET_MESSAGE, message);
    }

    /**
     * Обработчик ответов сокета
     * @param msg       Сообщение от сокета
     */
    onMessage(msg: any) {
        log.debug("onMessage msg", msg);
        // удаляем из списка не завершенных сообщений
        this.removeFromHistory(msg['msg_id']);
        // отправляем сообщение подписчикам
        for (let name of Object.keys(this.subscribers)) {
            if (msg.data) {
                msg.data.msg_id = msg['msg_id'];
            }
            this.subscribers[name](msg.data);
        }
    }

}

export default new Sockets();
