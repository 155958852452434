import cn from "classnames";
import React from 'react';
import { observer } from "mobx-react-lite";
import { useStore } from "@hooks/use-store";

import styles from './footer.module.scss';


const Footer = () => {

    const { mainStore } = useStore();

    return (
        <div className="styles_footer__l17aE">
            <a href="https://www.youtube.com/embed/zeIHBDyf7J0"
               className="styles_block__3lS7T"
               target="_blank"
               rel="noreferrer">
                <svg viewBox="0 0 32 24" fill="none" className="styles_icon__3-fSI styles_youtube__31A4Z">
                    <path d="M31.9964 12.6244C31.9875 13.3978 31.962 14.1629 31.92 14.9198C31.8667 15.7198 31.7933 16.5598 31.7 17.4398C31.6067 18.3198 31.48 19.0665 31.32 19.6798C31.1333 20.3731 30.7867 20.9731 30.28 21.4798C29.7733 21.9865 29.1733 22.3331 28.48 22.5198C27.8667 22.6798 26.8733 22.8065 25.5 22.8998C24.1267 22.9931 22.7333 23.0665 21.32 23.1198C19.6667 23.1731 17.8933 23.1998 16 23.1998H14.36C13.8533 23.1998 13.2733 23.1931 12.62 23.1798C11.9667 23.1665 11.28 23.1465 10.56 23.1198C10.5685 23.1007 10.5769 23.0815 10.5853 23.0623C9.2044 23.0093 7.84264 22.9371 6.5 22.8459C5.12667 22.7525 4.13333 22.6259 3.52 22.4659C2.82667 22.2792 2.22667 21.9325 1.72 21.4259C1.21333 20.9192 0.866667 20.3192 0.68 19.6259C0.52 19.0125 0.393333 18.2659 0.3 17.3859C0.206667 16.5059 0.133333 15.6659 0.08 14.8659C0.0398977 14.144 0.0148723 13.4147 0.00492361 12.6777C0.00328226 12.6784 0.00164106 12.6791 0 12.6798V11.9998C0 11.0131 0.026668 10.0398 0.0800013 9.07981C0.133335 8.27981 0.206669 7.43981 0.300002 6.55981C0.393336 5.67981 0.52 4.93314 0.68 4.31981C0.866667 3.62647 1.21333 3.02647 1.72 2.5198C2.22666 2.01314 2.82667 1.66647 3.52 1.4798C4.13333 1.3198 5.12667 1.19314 6.5 1.0998C7.87333 1.00647 9.26667 0.933138 10.68 0.879805C12.3333 0.826471 14.1067 0.799805 16 0.799805C17.8933 0.799805 19.6667 0.826471 21.32 0.879805C22.7333 0.933138 24.1267 1.00647 25.5 1.0998C26.8733 1.19314 27.8667 1.3198 28.48 1.4798C29.1733 1.66647 29.7733 2.01314 30.28 2.5198C30.7867 3.02647 31.1333 3.62647 31.32 4.31981C31.48 4.93314 31.6067 5.67981 31.7 6.55981C31.7933 7.43981 31.8667 8.27981 31.92 9.07981C31.9733 10.0398 32 11.0131 32 11.9998V12.6259C31.9988 12.6254 31.9976 12.6249 31.9964 12.6244Z" fill="#0000003D"/>
                    <path d="M12.8008 16.7455L21.1208 11.9455L12.8008 7.14551V16.7455Z" fill="white"/>
                </svg>
                <span>Как оплатить</span>
            </a>
            <a href={"https://t.me/" + mainStore.config.tg}
               className="styles_block__3lS7T"
               rel="noreferrer"
               target="_blank">
                <svg viewBox="0 0 32 32" fill="none" className="styles_icon__3-fSI styles_tg__18k7m">
                    <path d="M16 0C11.7575 0 7.685 1.68675 4.6875 4.68625C1.68691 7.68694 0.000831175 11.7565 0 16C0 20.2418 1.6875 24.3142 4.6875 27.3137C7.685 30.3132 11.7575 32 16 32C20.2425 32 24.315 30.3132 27.3125 27.3137C30.3125 24.3142 32 20.2418 32 16C32 11.7583 30.3125 7.68575 27.3125 4.68625C24.315 1.68675 20.2425 0 16 0Z" fill="#0000003D"/>
                    <path d="M7.2425 15.831C11.9075 13.799 15.0175 12.4593 16.5725 11.812C21.0175 9.96375 21.94 9.64275 22.5425 9.63188C22.675 9.62975 22.97 9.6625 23.1625 9.81813C23.3225 9.94938 23.3675 10.1269 23.39 10.2515C23.41 10.376 23.4375 10.6598 23.415 10.8813C23.175 13.4113 22.1325 19.5508 21.6025 22.3845C21.38 23.5835 20.9375 23.9855 20.51 24.0247C19.58 24.1103 18.875 23.4107 17.975 22.821C16.5675 21.8977 15.7725 21.3233 14.405 20.4225C12.825 19.3815 13.85 18.8092 14.75 17.8743C14.985 17.6295 19.08 13.9058 19.1575 13.568C19.1675 13.5258 19.1775 13.3683 19.0825 13.2853C18.99 13.202 18.8525 13.2305 18.7525 13.253C18.61 13.285 16.3625 14.772 12.0025 17.7138C11.365 18.1523 10.7875 18.366 10.2675 18.3547C9.6975 18.3425 8.5975 18.0318 7.78 17.7663C6.78 17.4405 5.9825 17.2683 6.0525 16.715C6.0875 16.427 6.485 16.1323 7.2425 15.831Z" fill="white"/>
                </svg>
                <span>Написать в поддержку</span>
            </a>
            <a href={"/payout"}
               className={cn("styles_block__3lS7T")}
               rel="noreferrer"
               target="_blank">
                <svg viewBox="0 0 24 24" fill="#b9baba" className="styles_icon__3-fSI styles_youtube__31A4Z">
                    <path d="M2,5H22V20H2V5M20,18V7H4V18H20M17,8A2,2 0 0,0 19,10V15A2,2 0 0,0 17,17H7A2,2 0 0,0 5,15V10A2,2 0 0,0 7,8H17M17,13V12C17,10.9 16.33,10 15.5,10C14.67,10 14,10.9 14,12V13C14,14.1 14.67,15 15.5,15C16.33,15 17,14.1 17,13M15.5,11A0.5,0.5 0 0,1 16,11.5V13.5A0.5,0.5 0 0,1 15.5,14A0.5,0.5 0 0,1 15,13.5V11.5A0.5,0.5 0 0,1 15.5,11M13,13V12C13,10.9 12.33,10 11.5,10C10.67,10 10,10.9 10,12V13C10,14.1 10.67,15 11.5,15C12.33,15 13,14.1 13,13M11.5,11A0.5,0.5 0 0,1 12,11.5V13.5A0.5,0.5 0 0,1 11.5,14A0.5,0.5 0 0,1 11,13.5V11.5A0.5,0.5 0 0,1 11.5,11M8,15H9V10H8L7,10.5V11.5L8,11V15Z" />
                </svg>
                <span>Получить выплату</span>
            </a>
            <a href={"/instructions"}
               className={cn("styles_block__3lS7T")}
               rel="noreferrer"
               target="_blank">
                <svg viewBox="0 0 24 24" fill="#b9baba" className="styles_icon__3-fSI styles_tg__18k7m">
                    <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22M11 7H13V9H11V7M14 17H10V15H11V13H10V11H13V15H14V17Z" />
                </svg>
                <span>Инструкции</span>
            </a>
        </div>
    );

};

export default observer(Footer);
