import log from "loglevel";
import moment from "moment";
import { makeAutoObservable } from "mobx";
import api from "@api/api";
import sockets from "@api/sockets";
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { RootStore } from "./root-store";
import { SOCKET_MESSAGE } from "@config/constats";
import { PaymentData } from "@helpers/payment-data";

class MainStore {

    private rootStore: RootStore;

    protected _config: any = {
        tg: "",             // tg для саппорта
        time: 12,           // время на оплату в мин
        site: ""            // урл для возврата при отказе от оплаты
    };
    protected _payment_id: string = "";
    protected _order_id: string = "";
    protected _amount: number = 1000;
    protected _method: string = "";
    protected _user_id: string = "";
    protected _phone: string = "";
    protected _bank: string = "";
    protected _name: string = "";
    protected _timer = 720;

    protected _ti: any;

    // region Геттеры и сеттеры
    get config() {
        return this._config;
    }
    set config(val) {
        this._config = val;
    }

    get payment_id() {
        return this._payment_id;
    }
    set payment_id(val) {
        this._payment_id = val;
    }

    get order_id() {
        return this._order_id;
    }
    set order_id(val) {
        this._order_id = val;
    }

    get amount() {
        return this._amount;
    }
    set amount(val) {
        this._amount = val;
    }

    get method() {
        return this._method;
    }
    set method(val) {
        this._method = val;
    }

    get phone() {
        return this._phone;
    }
    set phone(val) {
        this._phone = val;
    }

    get phoneFormatted() {
        const phoneNumber = parsePhoneNumberFromString(this.phone as string);
        return phoneNumber?.formatInternational()
    }

    get bank() {
        return this._bank;
    }
    set bank(val) {
        this._bank = val;
    }

    get name() {
        return this._name;
    }
    set name(val) {
        this._name = val;
    }

    get timer() {
        return this._timer;
    }
    set timer(val) {
        this._timer = val;
    }

    get user_id() {
        return this._user_id;
    }
    set user_id(val) {
        this._user_id = val;
    }

    /**
     * Получение VisitorID
     */
    vid() {
        // @ts-ignore
        return window.vid;
    }

    // endregion

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
        sockets.subscribe(SOCKET_MESSAGE, this.onSocketMessage.bind(this));
        this.loadConfig().then();
        this.register();
    }

    async loadConfig() {
        log.debug("loadConfig");
        try {
            const res = await api.config();
            log.debug("CONFIG", res.data);
            this.config = res.data;
            const path = "/offline"
            if (res.data?.mode === "off" && document.location.pathname !== path) {
                document.location.href = path;
            }
        } catch (ex) {
            console.log(ex);
        }
    }

    /**
     * Регистрация юзера для получения сокета
     */
    register() {
        log.debug("register");
        const ti = setInterval(() => {
            if (this.vid()) {
                clearInterval(ti);
                localStorage.setItem("token", this.vid());
                sockets.init();
                sockets.sendMessage({
                    message: "register",
                    vid: this.vid()
                });
            }
        }, 100);
    }

    /**
     * Обработчик входящих сообщений сокета
     * @param msg
     */
    async onSocketMessage(msg: any) {
        log.debug("sockets message", msg);
        switch (msg.message) {
            case 'finish':
                alert("Платеж принят");
                break;
            case 'requisites':
                if (msg.payment_id === this.payment_id) {
                    this.amount = msg.amount;
                    this.phone = msg.phone;
                    this.bank = msg.bank;
                    this.name = msg.name;
                    this.timer = this.config.time * 60;
                    this.saveDataToStorage();
                }
                break;
        }
    }

    /**
     * Таймер для страницы ожидания оплаты
     */
    startTimer() {
        log.debug("startTimer");
        this.stopTimer();
        this._ti = setInterval(() => {
            this.timer--;
            this.saveDataToStorage();
        }, 1000);
    }

    stopTimer() {
        log.debug("stopTimer");
        if (this._ti) {
            this.timer = this.config.time * 60;
            clearInterval(this._ti);
        }
    }

    async checkPayment(payment_id: string) {
        log.debug("checkPayment", payment_id);
        try {
            const res = await api.payment(payment_id);
            if (res.data.data?.status >= 3) {
                document.location.href = "/";
            }
        } catch (ex) {
            console.log(ex);
        }
    }

    async cancelPayment(payment_id: string, auto: number) {
        log.debug("cancelPayment payment_id:", payment_id, "auto:", auto);
        try {
            const res = await api.cancel(payment_id, auto);
            console.log(res.data);
        } catch (ex) {
            console.log(ex);
        }
    }

    saveDataToStorage() {
        log.debug("saveDataToStorage");
        const data: PaymentData = {
            payment_id: this.payment_id,
            order_id: this.order_id,
            amount: this.amount,
            method: this.method,
            phone: this.phone,
            bank: this.bank,
            name: this.name,
            tm: this.timer,
            timestamp: moment().unix()
        }
        localStorage.setItem("pm_" + this.payment_id, JSON.stringify(data));
    }

    loadDataFromStorage(payment_id: string) {
        log.debug("loadDataFromStorage", payment_id);
        let str = localStorage.getItem("pm_" + payment_id);
        if (str) {
            const data: PaymentData = JSON.parse(str);
            if (moment().unix() - data.timestamp <= data.tm) {
                this.payment_id = data.payment_id;
                this.order_id = data.order_id;
                this.amount = data.amount;
                this.method = data.method;
                this.phone = data.phone;
                this.bank = data.bank;
                this.name = data.name;
                this.timer = data.tm;
            } else {
                this.clearDataStorage(data.payment_id);
                document.location.href = "/";
            }
        }
    }

    clearDataStorage(payment_id: string) {
        log.debug("clearDataStorage", payment_id);
        localStorage.removeItem("pm_" + payment_id);
    }

    /**
     * Запрос реквизитов для платежа у админа
     * @param method
     */
    sendPaymentRequest(method: string) {
        log.debug("sendPaymentRequest");
        this.method = method;
        sockets.sendMessage({
            message: "payment_request",
            payment_id: this.payment_id,
            order_id: this.order_id,
            method: this.method,
            amount: this.amount,
            client_id: this.user_id
        });
    }

    /**
     * Подтверждение клиентом оплаты
     */
    clientPaymentConfirm() {
        log.debug("clientPaymentConfirm");
        sockets.sendMessage({
            message: "payment_confirm",
            payment_id: this.payment_id,
            order_id: this.order_id,
            method: this.method,
            amount: this.amount,
        });
    }

}

export default MainStore;