import { createContext, useContext } from "react";
import { RootStore } from "../store/root-store"

export const UseStore = createContext<RootStore | null>(null)

export const useStore = () => {
    const context = useContext(UseStore);
    if (context === null) {
        throw new Error(
            "You have forgotten to wrap your root component with RootStoreProvider"
        );
    }
    return context;
};